import checkPausePassword from '../../api/checkPausePassword';

const actions = {
  checkPassword: async (_, password) => {
    try {
      const result = await checkPausePassword.checkPassword(password);
      return result;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
