<template>
  <div
    class="h-full w-16 bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex justify-between flex-col"
  >
    <div class="flex flex-col items-center">
      <logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-10"
      />
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <a
        v-if="appointmentAgent"
        v-tooltip.right="'Agendamentos'"
        class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
        @click="openScheduleModal"
      >
        <fluent-icon icon="calendar-clock" />
      </a>
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <primary-nav-item
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        :open-in-new-page="true"
        :to="helpDocsURL"
      />
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
    <woot-modal
      :show.sync="showPauseModal"
      :on-close="hidePauseModal"
      :show-close-button="false"
      :full-width="true"
      :is-pause-modal="true"
    >
      <pause-modal :pause="pausesModel" @close="hidePauseModal" />
    </woot-modal>
    <woot-modal
      :show.sync="scheduleModal"
      :on-close="hideScheduleModal"
      :medium-width="true"
    >
      <schedule-modal @close="hideScheduleModal" />
    </woot-modal>
  </div>
</template>
<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import PauseModal from '../PauseModal.vue';
import ScheduleModal from './ScheduleModal.vue';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    PauseModal,
    ScheduleModal,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    appointmentAgent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showPauseModal: false,
      pausesModel: null,
      scheduleModal: false,
    };
  },
  mounted() {
    if (localStorage.getItem('pause-modal-open') === 'true') {
      this.pausesModel = JSON.parse(localStorage.getItem('pause-modal-pause'));
      this.showPauseModal = true;
    }
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    hidePauseModal() {
      this.showPauseModal = false;
    },
    openScheduleModal() {
      this.scheduleModal = true;
    },
    hideScheduleModal() {
      this.scheduleModal = false;
    },
  },
};
</script>
