<template>
  <div class="flex flex-col items-center justify-center">
    <!-- User Profile Section -->
    <div class="flex flex-col items-center mb-4">
      <thumbnail
        :src="currentUser.avatar_url"
        :username="currentUser.name"
        size="150px"
      />
      <h2 class="mt-4 text-xl">{{ currentUser.name }}</h2>
    </div>
    <!-- Pause Message Section -->
    <div
      class="bg-slate-300 dark:bg-slate-700 px-7 py-2 rounded-lg mb-2 w-96 text-center"
    >
      <label class="text-center text-sm w-full mb-4 mt-1">
        Digite a senha para retornar
        <input
          v-model="password"
          type="password"
          placeholder="Digite sua senha"
          class="mt-2"
        />
      </label>
      <woot-button
        :style="{
          display: 'inline-block',
          padding: '0.25rem 0.5rem',
          width: 'auto',
          textAlign: 'center',
          marginTop: '5px',
          marginBottom: '5px',
        }"
        size="small"
        :disabled="!password"
        @click="onSubmit"
      >
        Confirmar
      </woot-button>
    </div>
    <!-- Pause Timer Section -->
    <div class="text-center text-4xl">
      <h2 class="mt-2 mb-2 text-4xl">{{ pause.name }}</h2>
      <div :class="[timerClass, 'text-6xl']">{{ formattedTime }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    pause: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      timeRemaining: this.pause.duration,
      isCountingDown: true,
      intervalId: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    formattedTime() {
      const hours = Math.floor(this.timeRemaining / 3600)
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((this.timeRemaining % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const seconds = (this.timeRemaining % 60).toString().padStart(2, '0');
      return this.isCountingDown
        ? `${hours}:${minutes}:${seconds}`
        : `+${hours}:${minutes}:${seconds}`;
    },
    timerClass() {
      if (!this.isCountingDown) {
        return 'text-red-500';
      }

      if (this.timeRemaining <= this.pause.duration / 2) {
        return 'text-yellow-600';
      }

      return ''; // Cor padrão
    },
  },
  mounted() {
    localStorage.setItem('pause-modal-open', 'true');
    localStorage.setItem('pause-modal-pause', JSON.stringify(this.pause));
    window.addEventListener('keydown', this.preventKeyAction);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.startTimer();
    this.createPause();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.preventKeyAction);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    clearInterval(this.intervalId);
  },
  methods: {
    onClose() {
      localStorage.removeItem('pause-modal-open');
      localStorage.removeItem('pause-modal-pause');
      clearInterval(this.intervalId);
      this.$store.dispatch('usersPauses/finishPause', this.pause);
      this.$emit('close');
    },
    async onSubmit() {
      try {
        await this.$store.dispatch(
          'checkPausePassword/checkPassword',
          this.password
        );
        this.onClose();
      } catch (error) {
        const errorMessage = 'Senha inválida, tente novamente';
        bus.$emit('newToastMessage', errorMessage);
      }
    },
    preventKeyAction(event) {
      if (event.key === 'F5') {
        event.preventDefault();
      }
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.isCountingDown) {
          if (this.timeRemaining > 0) {
            this.timeRemaining -= 1;
          } else {
            this.isCountingDown = false;
          }
        } else {
          this.timeRemaining += 1;
        }
      }, 1000);
    },
    createPause() {
      this.$store.dispatch('usersPauses/createPause', this.pause);
    },
    handleBeforeUnload(event) {
      this.$store.dispatch('usersPauses/finishPause', this.pause);
      event.returnValue = '';
    },
  },
};
</script>
