var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col items-center justify-center"},[_c('div',{staticClass:"flex flex-col items-center mb-4"},[_c('thumbnail',{attrs:{"src":_vm.currentUser.avatar_url,"username":_vm.currentUser.name,"size":"150px"}}),_vm._v(" "),_c('h2',{staticClass:"mt-4 text-xl"},[_vm._v(_vm._s(_vm.currentUser.name))])],1),_vm._v(" "),_c('div',{staticClass:"bg-slate-300 dark:bg-slate-700 px-7 py-2 rounded-lg mb-2 w-96 text-center"},[_c('label',{staticClass:"text-center text-sm w-full mb-4 mt-1"},[_vm._v("\n      Digite a senha para retornar\n      "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"mt-2",attrs:{"type":"password","placeholder":"Digite sua senha"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_vm._v(" "),_c('woot-button',{style:({
        display: 'inline-block',
        padding: '0.25rem 0.5rem',
        width: 'auto',
        textAlign: 'center',
        marginTop: '5px',
        marginBottom: '5px',
      }),attrs:{"size":"small","disabled":!_vm.password},on:{"click":_vm.onSubmit}},[_vm._v("\n      Confirmar\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"text-center text-4xl"},[_c('h2',{staticClass:"mt-2 mb-2 text-4xl"},[_vm._v(_vm._s(_vm.pause.name))]),_vm._v(" "),_c('div',{class:[_vm.timerClass, 'text-6xl']},[_vm._v(_vm._s(_vm.formattedTime))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }