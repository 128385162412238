/* global axios */
import ApiClient from './ApiClient';

class CheckPausePasswordAPI extends ApiClient {
  constructor() {
    super('check_password', { accountScoped: true });
  }

  checkPassword(password) {
    return axios.post(`${this.url}`, {
      password,
    });
  }
}

export default new CheckPausePasswordAPI();
