/* global axios */
import ApiClient from './ApiClient';

class ScheduledConversationsAPI extends ApiClient {
  constructor() {
    super('scheduled_conversations', { accountScoped: true });
  }

  get({ type, agentId, from, to, page } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        since: from,
        until: to,
        type,
        agent_id: agentId,
      },
    });
  }

  getMetrics({ type, agentId, from, to } = {}) {
    return axios.get(`${this.url}/metrics`, {
      params: {
        type,
        since: from,
        until: to,
        agent_id: agentId,
      },
    });
  }

  cancel_scheduled_conversation(id) {
    return axios.delete(`${this.url}/cancel_scheduled_conversation`, {
      params: {
        conversation_id: id,
      },
    });
  }

  transferApointment({ conversationId, agentId }) {
    return axios.post(`${this.url}/transfer_appointment`, {
      conversation_id: conversationId,
      agent_id: agentId,
    });
  }

  changeAppointmentDate({ conversationId, date }) {
    return axios.post(`${this.url}/change_appointment_date`, {
      conversation_id: conversationId,
      date,
    });
  }
}

export default new ScheduledConversationsAPI();
