<template>
  <div class="scheduled-conversations-table-container">
    <ve-table
      max-height="calc(70vh - 21.875rem)"
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :row-style-option="rowStyleOption"
    />
    <div v-if="isLoading" class="scheduled-conversations-loader">
      <spinner />
      <span> Carregando agendamentos </span>
    </div>
    <empty-state
      v-else-if="!isLoading && !scheduledConversations.length"
      title="Sem agendamentos"
    />
    <div v-if="scheduledConversations.length > 0" class="table-pagination">
      <ve-pagination
        :total="scheduledConversationsMetrics"
        :page-index="schedulePageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
    <woot-modal :show.sync="showTransferModal" :on-close="hideTransferModal">
      <transfer-modal
        :selected-agent="selectedAgent"
        :inbox-id="selectedInboxId"
        :conversation-id="selectedConversationId"
        @close="hideTransferModal"
        @refresh="refreshCardData"
      />
    </woot-modal>
    <woot-modal
      :show.sync="showCustomSnoozeModal"
      :on-close="hideCustomSnoozeModal"
    >
      <custom-snooze-modal
        :agent-schedule="true"
        @close="hideCustomSnoozeModal"
        @choose-time="chooseSnoozeTime"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="'Confirmar cancelamento'"
      :message="'Você tem certeza que deseja cancelar o agendamento?'"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { VeTable, VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import timeMixin from 'dashboard/mixins/time';
import TransferModal from './TransferModal.vue';
import CustomSnoozeModal from '../../../../../../components/CustomSnoozeModal.vue';

export default {
  name: 'ScheduleTable',
  components: {
    EmptyState,
    Spinner,
    VeTable,
    VePagination,
    TransferModal,
    CustomSnoozeModal,
  },
  mixins: [rtlMixin, timeMixin],
  props: {
    scheduledConversations: {
      type: Array,
      default: () => [],
    },
    scheduledConversationsMetrics: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    schedulePageIndex: {
      type: Number,
      default: 1,
    },
    agentTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rowStyleOption: {
        hoverHighlight: false,
      },
      showDeletePopup: false,
      selectedScheduleId: null,
      selectedInboxId: null,
      selectedConversationId: null,
      showTransferModal: false,
      selectedAgent: null,
      showCustomSnoozeModal: false,
    };
  },
  computed: {
    tableData() {
      return this.scheduledConversations.map(conversation => {
        return {
          agent: conversation.assigned_agent ? conversation.assigned_agent : {},
          agentName: conversation.assigned_agent
            ? conversation.assigned_agent.name
            : '---',
          email: conversation.assigned_agent
            ? conversation.assigned_agent.email
            : '---',
          agentThumbnail: conversation.assigned_agent
            ? conversation.assigned_agent.thumbnail
            : '',
          agentStatus: conversation.assigned_agent
            ? conversation.assigned_agent.availability_status
            : '',
          contact: conversation.contact ? conversation.contact.name : '---',
          phoneNumber: conversation.contact
            ? conversation.contact.phone_number
            : '---',
          contactThumbnail: conversation.contact
            ? conversation.contact.thumbnail
            : '',
          contactStatus: conversation.contact
            ? conversation.contact.availability_status
            : '',
          createdAt: this.messageStamp(
            conversation.snoozed_created_at,
            'dd/MM/yyyy, kk:mm'
          ),
          snoozed_until:
            conversation.snoozed_until !== 0
              ? this.messageStamp(
                  conversation.snoozed_until,
                  'dd/MM/yyyy, kk:mm'
                )
              : 'Próxima resposta',
          remainingTime:
            conversation.snoozed_until !== 0
              ? this.formatTime(conversation.snoozed_until - Date.now() / 1000)
              : '',
          conversationId: conversation.id,
          inboxId: conversation.inbox_id,
        };
      });
    },
    columns() {
      const baseColumns = [
        {
          field: 'contact',
          key: 'contact',
          title: 'Cliente',
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.contactThumbnail}
                size="32px"
                username={row.contact}
                status={row.contactStatus}
              />
              <div class="user-block">
                <h6 class="title overflow-hidden whitespace-nowrap text-ellipsis">
                  {row.contact}
                </h6>
                {!this.agentTable && (
                  <span class="sub-title">{row.phoneNumber}</span>
                )}
              </div>
            </div>
          ),
        },
        {
          field: 'snoozed_until',
          key: 'snoozed_until',
          title: 'Data agendada',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'remainingTime',
          key: 'remainingTime',
          title: 'Tempo restante',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'conversationId',
          key: 'conversationId',
          title: '',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            const routerParams = this.$router.resolve({
              name: 'inbox_conversation',
              params: { conversation_id: row.conversationId },
            }).href;
            return (
              <div class="text-right flex justify-end">
                <div>
                  <a
                    href={routerParams}
                    target={this.agentTable ? '_self' : '_blank'}
                  >
                    <woot-button
                      color-scheme="secondary"
                      icon="chat"
                      variant="clear"
                      size="large"
                    />
                  </a>
                </div>
                <div>
                  <woot-button
                    color-scheme="secondary"
                    icon={this.agentTable ? 'calendar-clock' : 'arrow-swap'}
                    variant="clear"
                    size="large"
                    on-click={() =>
                      this.agentTable
                        ? this.openCustomSnoozeModal(row.conversationId)
                        : this.openTransferModal(
                            row.inboxId,
                            row.agent,
                            row.conversationId
                          )
                    }
                  />
                </div>
                <div>
                  <woot-button
                    color-scheme="alert"
                    icon="dismiss-circle"
                    variant="clear"
                    size="large"
                    on-click={() => this.openDeletePopup(row.conversationId)}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      if (!this.agentTable) {
        baseColumns.unshift({
          field: 'agent',
          key: 'agent',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'
          ),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.agentThumbnail}
                size="32px"
                username={row.agentName}
                status={row.agentStatus}
              />
              <div class="user-block">
                <h6 class="title overflow-hidden whitespace-nowrap text-ellipsis">
                  {row.agentName}
                </h6>
                <span class="sub-title">{row.email}</span>
              </div>
            </div>
          ),
        });

        baseColumns.splice(2, 0, {
          field: 'createdAt',
          key: 'createdAt',
          title: 'Início',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        });
      } else {
        baseColumns.splice(1, 0, {
          field: 'phoneNumber',
          key: 'phoneNumber',
          title: 'Telefone',
          align: this.isRTLView ? 'right' : 'left',
          width: 150,
        });
      }

      return baseColumns;
    },
    deleteConfirmText() {
      return `Sim, cancelar agendamento`;
    },
    deleteRejectText() {
      return `Não, manter agendamento`;
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    async deleteSchecule(conversationId) {
      try {
        await this.$store.dispatch(
          'scheduledConversations/cancel_scheduled_conversation',
          conversationId
        );
        this.showAlert('Agendamento cancelado com sucesso');
      } catch (error) {
        const errorMessage =
          error?.message || 'Ocorreu um erro ao cancelar o agendamento';
        this.showAlert(errorMessage);
      } finally {
        this.$emit('refresh');
      }
    },
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600)
        .toString()
        .padStart(2, '0');
      const m = Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const s = Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0');
      return `${h}:${m}:${s}`;
    },
    openDeletePopup(conversationId) {
      this.showDeletePopup = true;
      this.selectedScheduleId = conversationId;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteSchecule(this.selectedScheduleId);
    },
    showAlert(message) {
      this.selectedScheduleId = null;
      bus.$emit('newToastMessage', message);
    },
    openTransferModal(inboxId, agent, conversationId) {
      this.selectedInboxId = inboxId;
      this.selectedAgent = agent;
      this.selectedConversationId = conversationId;
      this.showTransferModal = true;
    },
    hideTransferModal() {
      this.selectedInboxId = null;
      this.selectedAgent = null;
      this.selectedConversationId = null;
      this.showTransferModal = false;
    },
    refreshCardData() {
      this.$emit('refresh');
    },
    openCustomSnoozeModal(conversationId) {
      this.selectedConversationId = conversationId;
      this.showCustomSnoozeModal = true;
    },
    hideCustomSnoozeModal() {
      this.selectedConversationId = null;
      this.showCustomSnoozeModal = false;
    },
    async chooseSnoozeTime(snoozeTime) {
      this.showCustomSnoozeModal = false;

      try {
        await this.$store.dispatch(
          'scheduledConversations/changeAppointmentDate',
          {
            conversationId: this.selectedConversationId,
            date: snoozeTime,
          }
        );
        this.showAlert('Data de agendamento alterada com sucesso');
      } catch (error) {
        const errorMessage =
          error?.message || 'Ocorreu um erro ao alterar a data de agendamento';
        this.showAlert(errorMessage);
      } finally {
        this.$emit('refresh');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scheduled-conversations-table-container {
  @apply flex flex-col flex-1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    @apply bg-transparent dark:bg-transparent;
  }

  &::v-deep .ve-pagination-select {
    @apply hidden;
  }

  .row-user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col min-w-0 my-0 mx-2;

      .title {
        @apply text-sm m-0 leading-[1.2] text-slate-800 dark:text-slate-100;
      }
      .sub-title {
        @apply text-xs text-slate-600 dark:text-slate-200;
      }
    }
  }

  .table-pagination {
    @apply mt-4 text-right;
  }
}

.scheduled-conversations-loader {
  @apply items-center flex text-base justify-center p-8;
}
</style>
