import LlamaAiApi from '../../api/llamaAi';

const state = {
  aiReply: null,
  aiEnhance: null,
  aiCorrect: null,
  loading: false,
  error: null,
};

const getters = {
  isLoading: _state => _state.loading,
  error: _state => _state.error,
  aiReply: _state => _state.aiReply,
  aiEnhance: _state => _state.aiEnhance,
  aiCorrect: _state => _state.aiCorrect,
};

const actions = {
  async replyWithAi({ commit }, { messageId, conversationId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await LlamaAiApi.replyWithAi({
        messageId,
        conversationId,
      });
      commit('SET_AI_REPLY', response.data);
      return response.data;
    } catch (error) {
      const errorMsg =
        error.response && error.response.status === 422
          ? error.response.data.error || 'Limite de requisições excedido'
          : 'Erro desconhecido';
      commit('SET_ERROR', errorMsg);
      throw new Error(errorMsg);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async improveWithAi({ commit }, { text }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await LlamaAiApi.improveWithAi({ text });
      commit('SET_AI_ENHANCE', response.data);
      return response.data;
    } catch (error) {
      const errorMsg =
        error.response && error.response.status === 422
          ? error.response.data.error || 'Limite de requisições excedido'
          : 'Erro desconhecido';
      commit('SET_ERROR', errorMsg);
      throw new Error(errorMsg);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async correctWithAi({ commit }, { text }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await LlamaAiApi.correctWithAi({ text });
      commit('SET_AI_CORRECT', response.data);
      return response.data;
    } catch (error) {
      const errorMsg =
        error.response && error.response.status === 422
          ? error.response.data.error || 'Limite de requisições excedido'
          : 'Erro desconhecido';
      commit('SET_ERROR', errorMsg);
      throw new Error(errorMsg);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const mutations = {
  SET_LOADING(_state, isLoading) {
    _state.loading = isLoading;
  },
  SET_ERROR(_state, error) {
    _state.error = error;
  },
  SET_AI_REPLY(_state, aiReply) {
    _state.aiReply = aiReply;
  },
  SET_AI_ENHANCE(_state, aiEnhance) {
    _state.aiEnhance = aiEnhance;
  },
  SET_AI_CORRECT(_state, aiCorrect) {
    _state.aiCorrect = aiCorrect;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
