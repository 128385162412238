/* global axios */
import ApiClient from './ApiClient';

class SlaReportAPI extends ApiClient {
  constructor() {
    super('sla_report', { accountScoped: true });
  }

  get({ page, from, to, user_ids, inbox_id, team_id, sla_ids } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        inbox_id,
        team_id,
        sla_ids,
      },
    });
  }

  download({ from, to, user_ids, inbox_id, team_id, sla_ids } = {}) {
    return axios.get(`${this.url}/download`, {
      params: {
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        inbox_id,
        team_id,
        sla_ids,
      },
    });
  }

  getMetrics({ from, to, user_ids, inbox_id, team_id, sla_ids } = {}) {
    return axios.get(`${this.url}/metrics`, {
      params: {
        since: from,
        until: to,
        user_ids,
        inbox_id,
        team_id,
        sla_ids,
      },
    });
  }
}

export default new SlaReportAPI();
