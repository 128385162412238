import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import appointmentReport from '../../api/appointmentReport';
import { downloadCsvFile } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingMetrics: false,
  },
  metrics: {},
};

export const getters = {
  getappointmentReport(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getMetrics(_state) {
    return _state.metrics;
  },
};

export const actions = {
  get: async function getAppointments({ commit }, params) {
    commit(types.SET_APPOINTMENT_REPORT_UI_FLAG, { isFetching: true });
    try {
      const response = await appointmentReport.get(params);
      commit(types.SET_APPOINTMENT_REPORT, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_APPOINTMENT_REPORT_UI_FLAG, { isFetching: false });
    }
  },
  getMetrics: async function getMetrics({ commit }, params) {
    commit(types.SET_APPOINTMENT_REPORT_UI_FLAG, { isFetchingMetrics: true });
    try {
      const response = await appointmentReport.getMetrics(params);
      commit(types.SET_APPOINTMENT_REPORT_METRICS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_APPOINTMENT_REPORT_UI_FLAG, {
        isFetchingMetrics: false,
      });
    }
  },
  downloadAppointmentReport(_, params) {
    return appointmentReport.download(params).then(response => {
      downloadCsvFile(params.fileName, response.data);
      AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
        reportType: 'appointment',
      });
    });
  },
};

export const mutations = {
  [types.SET_APPOINTMENT_REPORT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_APPOINTMENT_REPORT]: MutationHelpers.set,
  [types.SET_APPOINTMENT_REPORT_METRICS](_state, data) {
    _state.metrics = {
      ..._state.metrics,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
