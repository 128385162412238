/* global axios */
import ApiClient from './ApiClient';

class PauseReportAPI extends ApiClient {
  constructor() {
    super('pause_report', { accountScoped: true });
  }

  get({ page, from, to, user_ids, team_id, pauses_ids } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        team_id,
        pauses_ids,
      },
    });
  }

  download({ from, to, user_ids, team_id, pauses_ids } = {}) {
    return axios.get(`${this.url}/download`, {
      params: {
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        team_id,
        pauses_ids,
      },
    });
  }

  getMetrics({ from, to, user_ids, team_id, pauses_ids } = {}) {
    // no ratings for metrics
    return axios.get(`${this.url}/metrics`, {
      params: {
        since: from,
        until: to,
        user_ids,
        team_id,
        pauses_ids,
      },
    });
  }
}

export default new PauseReportAPI();
