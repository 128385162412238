<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOptions"
      class="no-margin"
      :options="options"
      track-by="id"
      label="name"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :hide-selected="true"
      :show-labels="false"
      placeholder="Escolher pausas"
      @input="handleInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsFiltersPause',
  data() {
    return {
      selectedOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      options: 'pauses/getPauses',
    }),
  },
  mounted() {
    this.$store.dispatch('pauses/getPauses');
  },
  methods: {
    handleInput() {
      this.$emit('pause-filter-selection', this.selectedOptions);
    },
  },
};
</script>
