import usersPauses from '../../api/usersPauses';

const actions = {
  createPause: async (_, data) => {
    try {
      const result = await usersPauses.create(data);
      return result;
    } catch (error) {
      throw new Error(error);
    }
  },
  finishPause: async (_, data) => {
    try {
      const result = await usersPauses.finishPause(data);
      return result;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
