<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOption"
      class="no-margin"
      track-by="name"
      label="name"
      :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
      selected-label
      :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
      deselect-label=""
      :options="options"
      :searchable="false"
      :allow-empty="false"
      :show-labels="false"
      @select="updateRange"
    />
  </div>
</template>

<script>
import { DATE_RANGE_OPTIONS } from '../../constants';

const EVENT_NAME = 'on-range-change';

export default {
  name: 'ReportFiltersDateRange',
  props: {
    appointmentsTable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    const translatedOptions = Object.values(DATE_RANGE_OPTIONS).map(option => ({
      ...option,
      name: this.$t(option.translationKey),
    }));

    const filteredOptions = this.appointmentsTable
      ? translatedOptions.filter(option =>
          ['YESTERDAY', 'TODAY', 'CUSTOM_DATE_RANGE'].includes(option.id)
        )
      : translatedOptions;

    return {
      // relies on translations, need to move it to constants
      selectedOption: this.appointmentsTable
        ? filteredOptions[1]
        : filteredOptions[2],
      options: filteredOptions,
    };
  },
  methods: {
    updateRange(selectedRange) {
      this.selectedOption = selectedRange;
      this.$emit(EVENT_NAME, selectedRange);
    },
  },
};
</script>
