/* global axios */
import ApiClient from './ApiClient';

class LlamaAiApi extends ApiClient {
  constructor() {
    super('completions', { accountScoped: true });
  }

  replyWithAi({ messageId, conversationId }) {
    return axios.post(`${this.url}/reply_with_ai`, {
      conversation_id: conversationId,
      message_id: messageId,
    });
  }

  improveWithAi({ text }) {
    return axios.post(`${this.url}/improve_with_ai`, {
      text: text,
    });
  }

  correctWithAi({ text }) {
    return axios.post(`${this.url}/correct_with_ai`, {
      text: text,
    });
  }
}

export default new LlamaAiApi();
