import TeamTabulationAPI from '../../api/teamTabulations';

const state = {
  tabulations: [],
  loading: false,
  error: null,
};

const getters = {
  allTabulations: _state => _state.tabulations,
  isLoading: _state => _state.loading,
  hasError: _state => _state.error,
};

const actions = {
  async fetchTabulations({ commit }, teamId) {
    commit('setLoading', true);
    try {
      const response = await TeamTabulationAPI.getTabulations({ teamId });
      commit('setTabulations', response.data);
      commit('setError', null);
    } catch (error) {
      commit(
        'setError',
        error.response?.data?.message || 'Failed to fetch tabulations'
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async addTabulations({ commit }, { teamId, tabulationList }) {
    commit('setLoading', true);
    try {
      await TeamTabulationAPI.addTabulations({ teamId, tabulationList });
      commit('setError', null);
    } catch (error) {
      commit(
        'setError',
        error.response?.data?.message || 'Failed to add tabulations'
      );
    } finally {
      commit('setLoading', false);
    }
  },

  async updateTabulations({ commit }, { teamId, tabulationList }) {
    commit('setLoading', true);
    try {
      await TeamTabulationAPI.updateTabulations({ teamId, tabulationList });
      commit('setError', null);
    } catch (error) {
      commit(
        'setError',
        error.response?.data?.message || 'Failed to update tabulations'
      );
    } finally {
      commit('setLoading', false);
    }
  },
};

const mutations = {
  setTabulations(_state, tabulations) {
    _state.tabulations = tabulations;
  },
  setLoading(_state, loading) {
    _state.loading = loading;
  },
  setError(_state, error) {
    _state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
