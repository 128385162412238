/* global axios */
import ApiClient from './ApiClient';

class UsersPausesAPI extends ApiClient {
  constructor() {
    super('users_pauses', { accountScoped: true });
  }

  finishPause(data) {
    return axios.post(`${this.url}/finish_pause`, data);
  }
}

export default new UsersPausesAPI();
