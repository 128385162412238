<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header header-title="Transferência de conversa" />
    <div class="w-full px-8 pb-4 pt-4">
      <div class="multiselect-wrap--small mb-32">
        <contact-details-item compact title="Atendente atribuído" />
        <multiselect-dropdown
          :options="assignableAgents"
          :selected-item="assignedAgent"
          :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT')"
          :multiselector-placeholder="
            $t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')
          "
          :no-search-result="
            $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')
          "
          :input-placeholder="
            $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')
          "
          @click="onClickAssignAgent"
        />
      </div>
      <div class="flex flex-row justify-end gap-2 pt-2 px-0 w-full">
        <button class="button clear" @click.prevent="onClose">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactDetailsItem from '../../../../conversation/ContactDetailsItem.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import agentMixin from '../../../../../../mixins/agentMixin';
import alertMixin from '../../../../../../../shared/mixins/alertMixin';

export default {
  components: {
    ContactDetailsItem,
    MultiselectDropdown,
  },
  mixins: [agentMixin, alertMixin],
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    selectedAgent: {
      type: Object,
      default: null,
    },
    conversationId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    assignedAgent: {
      get() {
        return this.selectedAgent;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.$store
          .dispatch('scheduledConversations/transferApointment', {
            conversationId: this.conversationId,
            agentId,
          })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
            this.$emit('refresh');
            this.onClose();
          });
      },
    },
    assignableAgents() {
      const list = this.$store.getters[
        'inboxAssignableAgents/getAssignableAgents'
      ](this.inboxId);

      return list.filter(agent => agent.id !== this.selectedAgent?.id);
    },
  },
  mounted() {
    this.$store.dispatch('inboxAssignableAgents/fetch', [this.inboxId]);
  },
  methods: {
    onClickAssignAgent(selectedItem) {
      if (this.assignedAgent && this.assignedAgent.id === selectedItem.id) {
        this.assignedAgent = null;
      } else {
        this.assignedAgent = selectedItem;
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
