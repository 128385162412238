import { throwErrorMessage } from 'dashboard/store/utils/api';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import PausesApi from '../../api/pauses';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getPauses(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  getPauses: async function getPauses({ commit }) {
    commit(types.SET_PAUSE_UI_FLAG, { fetchingList: true });
    try {
      const response = await PausesApi.get();
      commit(types.SET_PAUSE, response.data);
      commit(types.SET_PAUSE_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.SET_PAUSE_UI_FLAG, { fetchingList: false });
    }
  },

  createPause: async function createPause({ commit }, pauseObj) {
    commit(types.SET_PAUSE_UI_FLAG, { creatingItem: true });
    try {
      const response = await PausesApi.create(pauseObj);
      commit(types.ADD_PAUSE, response.data);
      commit(types.SET_PAUSE_UI_FLAG, { creatingItem: false });
      return response.data;
    } catch (error) {
      commit(types.SET_PAUSE_UI_FLAG, { creatingItem: false });
      return throwErrorMessage(error);
    }
  },

  updatePause: async function updatePause({ commit }, { id, ...pauseObj }) {
    commit(types.SET_PAUSE_UI_FLAG, { updatingItem: true });
    try {
      const response = await PausesApi.update(id, pauseObj);
      commit(types.EDIT_PAUSE, response.data);
      commit(types.SET_PAUSE_UI_FLAG, { updatingItem: false });
      return response.data;
    } catch (error) {
      commit(types.SET_PAUSE_UI_FLAG, { updatingItem: false });
      return throwErrorMessage(error);
    }
  },

  deletePause: async function detetePause({ commit }, id) {
    commit(types.SET_PAUSE_UI_FLAG, { deletingItem: true });
    try {
      await PausesApi.delete(id);
      commit(types.DELETE_PAUSE, id);
      commit(types.SET_PAUSE_UI_FLAG, { deletingItem: false });
    } catch (error) {
      commit(types.SET_PAUSE_UI_FLAG, { deletingItem: false });
    }
  },
};

export const mutations = {
  [types.SET_PAUSE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_PAUSE]: MutationHelpers.set,
  [types.ADD_PAUSE]: MutationHelpers.create,
  [types.EDIT_PAUSE]: MutationHelpers.update,
  [types.DELETE_PAUSE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
