<template>
  <mention-box
    v-if="items.length"
    :items="items"
    @mention-select="handleMentionClick"
    @scroll-end="loadMore"
  >
    <template slot-scope="{ item }">
      <strong>{{ item.label }}</strong> - {{ item.description }}
    </template>
  </mention-box>
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';
import { debounce } from '@chatwoot/utils';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debounceSearch: () => {},
      page: 1,
      perPage: 10,
      allItems: [],
      itemsCount: 0,
      hasMore: true,
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
    }),
    items() {
      return this.allItems.map(cannedMessage => (this.mountedMessage(cannedMessage)));
    },
  },
  watch: {
    searchKey() {
      this.debounceSearch();
    }
  },
  mounted() {
    this.fetchCannedResponses();

    this.debounceSearch = debounce(
      () => {
        this.page = 1;
        this.allItems = [];
        this.hasMore = true;
        this.fetchCannedResponses();
      },
      500,
      false
    );
  },
  methods: {
    mountedMessage(cannedMessage) {
      return {
        label: cannedMessage.short_code,
        key: cannedMessage.id,
        description: cannedMessage.content,
        image_url: cannedMessage.image_url || [],
        images: cannedMessage.images || [],
        attachments: cannedMessage.attachments || [],
      }
    },
    async fetchCannedResponses() {
      try {
        const response = await this.$store.dispatch('getCannedResponse',
        {
          searchKey: this.searchKey,
          page: this.page,
          perPage: this.perPage
        });

        if (this.page === 1) {
          this.allItems = response.data.canned_responses;
          this.itemsCount = response.data.total_count;
        } else if (response.data.canned_responses.length) {
          this.allItems = [...this.allItems, ...response.data.canned_responses];
        }

        if (this.allItems.length >= this.itemsCount) {
          this.hasMore = false;
        }
      } catch (error) {}
    },
    loadMore() {
      if (this.hasMore && !this.uiFlags.fetchingList) {
        this.page += 1;
        this.fetchCannedResponses();
      }
    },
    async handleMentionClick(item = {}) {
      if (item.key) {
        try {
          const response = await this.$store.dispatch('fetchCannedResponse', item.key);
          if (response && response.data) {
            item = this.mountedMessage(response.data);
          }
        } catch (error) {}
      }

      this.$emit('click', item);
    },
  },
};
</script>
