/* global axios */
import ApiClient from './ApiClient';

class TeamTabulations extends ApiClient {
  constructor() {
    super('teams', { accountScoped: true });
  }

  getTabulations({ teamId }) {
    return axios.get(`${this.url}/${teamId}/teams_tabulations`);
  }

  addTabulations({ teamId, tabulationList }) {
    return axios.post(`${this.url}/${teamId}/teams_tabulations`, {
      tabulations_ids: tabulationList,
    });
  }

  updateTabulations({ teamId, tabulationList }) {
    return axios.patch(`${this.url}/${teamId}/teams_tabulations`, {
      tabulations_ids: tabulationList,
    });
  }
}

export default new TeamTabulations();
