/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  async get({ searchKey, page = 1, perPage = 10 }) {
    let url = this.url;
    const params = new URLSearchParams();

    if (searchKey) {
      params.append('search', searchKey);
    }
    if (page) {
      params.append('page', page);
    }
    if (perPage) {
      params.append('perPage', perPage);
    }

    if (params.toString()) {
      url = `${url}?${params.toString()}`;
    }

    return await axios.get(url);
  }

  async fetch(id) {
    const url = `${this.url}/${id}`;
    return await axios.get(url);
  }
}

export default new CannedResponse();
