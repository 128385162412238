<template>
  <div class="flex">
    <div class="flex-col mt-8 mr-5 ml-5">
      <div>
        <report-filter-selector
          :show-business-hours-switch="false"
          :appointments-table="true"
          @filter-change="onFilterChange"
        />
      </div>
      <schedule-table
        :scheduled-conversations="scheduledConversationsData"
        :scheduled-conversations-metrics="
          scheduledConversationsMetric.total_count
        "
        :page-index="schedulePageIndex"
        :agent-table="true"
        @page-change="onSchedulePageChange"
        @refresh="refreshCardData('ConversationScheduledMetric')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScheduleTable from '../../../routes/dashboard/settings/reports/components/overview/ScheduleTable.vue';
import ReportFilterSelector from '../../../routes/dashboard/settings/reports/components/FilterSelector.vue';

export default {
  components: {
    ScheduleTable,
    ReportFilterSelector,
  },
  data() {
    return {
      schedulePageIndex: 1,
      from: 0,
      to: 0,
    };
  },
  computed: {
    ...mapGetters({
      scheduledConversationsData:
        'scheduledConversations/getScheduledConversations',
      scheduledConversationsMetric: 'scheduledConversations/getMetrics',
      currentUserID: 'getCurrentUserID',
    }),
  },
  methods: {
    fetchAllData() {
      this.fetchScheduledConversationsMetric();
      this.fetchScheduledConversationsData();
    },
    fetchScheduledConversationsData() {
      this.$store.dispatch('scheduledConversations/get', {
        type: 'agent',
        agentId: this.currentUserID,
        from: this.from,
        to: this.to,
        page: this.schedulePageIndex,
      });
    },
    fetchScheduledConversationsMetric() {
      this.$store.dispatch('scheduledConversations/getMetrics', {
        type: 'agent',
        agentId: this.currentUserID,
        from: this.from,
        to: this.to,
      });
    },
    onSchedulePageChange(pageIndex) {
      this.schedulePageIndex = pageIndex;
      this.fetchScheduledConversationsData();
      this.fetchScheduledConversationsMetric();
    },
    refreshCardData(type) {
      switch (type) {
        case 'ConversationScheduledMetric':
          this.fetchScheduledConversationsMetric();
          this.fetchScheduledConversationsData();
          break;
        default:
          break;
      }
    },
    onFilterChange({ from, to }) {
      this.from = from;
      this.to = to;

      this.schedulePageIndex = 1;
      this.fetchAllData();
    },
  },
};
</script>
