<template>
  <woot-button
    :size="size"
    v-tooltip.right="textTooltipBtnSidebar"
    variant="clear"
    color-scheme="secondary"
    class="-ml-3 text-black-900 dark:text-slate-300"
    icon="list"
    @click="onMenuItemClick"
  />
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { mapGetters } from 'vuex';

export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUISettings',
    }),
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    textTooltipBtnSidebar() {
      if (this.isSidebarOpen) {
        return this.$t('CONVERSATION.HIDE_SIDEBAR');
      }
      return this.$t('CONVERSATION.SHOW_SIDEBAR');
    },
  },
  methods: {
    onMenuItemClick() {
      bus.$emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>
