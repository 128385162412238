import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import Scheduled_conversations from '../../api/scheduled_conversations';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingMetrics: false,
  },
  metrics: {},
};

export const getters = {
  getScheduledConversations(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getMetrics(_state) {
    return _state.metrics;
  },
};

export const actions = {
  get: async function getScheduledConversations({ commit }, data) {
    commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: true });
    try {
      const response = await Scheduled_conversations.get(data);
      commit(types.SET_SCHEDULED_CONVERSATION, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: false });
    }
  },
  getMetrics: async function getMetrics({ commit }, params) {
    commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, {
      isFetchingMetrics: true,
    });
    try {
      const response = await Scheduled_conversations.getMetrics(params);
      commit(types.SET_SCHEDULED_CONVERSATION_METRICS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, {
        isFetchingMetrics: false,
      });
    }
  },
  cancel_scheduled_conversation: async function cancel_scheduled_conversation(
    { commit },
    id
  ) {
    commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: true });
    try {
      await Scheduled_conversations.cancel_scheduled_conversation(id);
      commit(types.DELETE_SCHEDULED_CONVERSATION, id);
    } catch (error) {
      // Ignore error
      throw new Error('Erro ao cancelar a conversa agendada');
    } finally {
      commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: false });
    }
  },
  transferApointment: async function transferApointment({ commit }, data) {
    commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: true });
    try {
      await Scheduled_conversations.transferApointment(data);
    } catch (error) {
      // Ignore error
      throw new Error('Erro ao transferir a conversa agendada');
    } finally {
      commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: false });
    }
  },
  changeAppointmentDate: async function changeAppointmentDate(
    { commit },
    data
  ) {
    commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: true });
    try {
      await Scheduled_conversations.changeAppointmentDate(data);
    } catch (error) {
      // Ignore error
      throw new Error('Erro ao alterar a data da conversa agendada');
    } finally {
      commit(types.SET_SCHEDULED_CONVERSATION_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_SCHEDULED_CONVERSATION_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_SCHEDULED_CONVERSATION]: MutationHelpers.set,
  [types.SET_SCHEDULED_CONVERSATION_METRICS](_state, data) {
    _state.metrics = {
      ..._state.metrics,
      ...data,
    };
  },
  [types.DELETE_SCHEDULED_CONVERSATION](_state, id) {
    _state.records = _state.records.filter(record => record.id !== id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
