export const debounceWithImmediateBlock = (func, wait) => {
  let timeout;

  return function(...args) {
    const context = this;

    if (!timeout) {
      func.apply(context, args);
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
    }
  };
};

export const normalizeString = (str = '') => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
