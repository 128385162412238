export const formatTime = timeInSeconds => {
  if (!timeInSeconds) {
    return '';
  }

  if (timeInSeconds < 60) {
    return `${timeInSeconds}s`;
  }

  if (timeInSeconds < 3600) {
    const minutes = Math.floor(timeInSeconds / 60);
    return `${minutes}m`;
  }

  if (timeInSeconds < 86400) {
    const hours = Math.floor(timeInSeconds / 3600);
    return `${hours}h`;
  }

  const days = Math.floor(timeInSeconds / 86400);
  return `${days}d`;
};

export const GROUP_BY_FILTER = {
  1: { id: 1, period: 'day' },
  2: { id: 2, period: 'week' },
  3: { id: 3, period: 'month' },
  4: { id: 4, period: 'year' },
};

export const GROUP_BY_OPTIONS = {
  DAY: {
    id: 'DAY',
    period: 'day',
    translationKey: 'REPORT.GROUPING_OPTIONS.DAY',
  },
  WEEK: {
    id: 'WEEK',
    period: 'week',
    translationKey: 'REPORT.GROUPING_OPTIONS.WEEK',
  },
  MONTH: {
    id: 'MONTH',
    period: 'month',
    translationKey: 'REPORT.GROUPING_OPTIONS.MONTH',
  },
  YEAR: {
    id: 'YEAR',
    period: 'year',
    translationKey: 'REPORT.GROUPING_OPTIONS.YEAR',
  },
};

export const DATE_RANGE_OPTIONS = {
  YESTERDAY: {
    id: 'YESTERDAY',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.YESTERDAY',
    offset: 1,
    groupByOptions: [GROUP_BY_OPTIONS.DAY],
  },
  TODAY: {
    id: 'TODAY',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.TODAY',
    offset: 0,
    groupByOptions: [GROUP_BY_OPTIONS.DAY],
  },
  LAST_7_DAYS: {
    id: 'LAST_7_DAYS',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.LAST_7_DAYS',
    offset: 6,
    groupByOptions: [GROUP_BY_OPTIONS.DAY],
  },
  LAST_30_DAYS: {
    id: 'LAST_30_DAYS',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.LAST_30_DAYS',
    offset: 29,
    groupByOptions: [GROUP_BY_OPTIONS.DAY, GROUP_BY_OPTIONS.WEEK],
  },
  LAST_3_MONTHS: {
    id: 'LAST_3_MONTHS',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.LAST_3_MONTHS',
    offset: 89,
    groupByOptions: [
      GROUP_BY_OPTIONS.DAY,
      GROUP_BY_OPTIONS.WEEK,
      GROUP_BY_OPTIONS.MONTH,
    ],
  },
  LAST_6_MONTHS: {
    id: 'LAST_6_MONTHS',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.LAST_6_MONTHS',
    offset: 179,
    groupByOptions: [GROUP_BY_OPTIONS.WEEK, GROUP_BY_OPTIONS.MONTH],
  },
  LAST_YEAR: {
    id: 'LAST_YEAR',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.LAST_YEAR',
    offset: 364,
    groupByOptions: [GROUP_BY_OPTIONS.WEEK, GROUP_BY_OPTIONS.MONTH],
  },
  CUSTOM_DATE_RANGE: {
    id: 'CUSTOM_DATE_RANGE',
    translationKey: 'REPORT.DATE_RANGE_OPTIONS.CUSTOM_DATE_RANGE',
    offset: null,
    groupByOptions: [
      GROUP_BY_OPTIONS.DAY,
      GROUP_BY_OPTIONS.WEEK,
      GROUP_BY_OPTIONS.MONTH,
      GROUP_BY_OPTIONS.YEAR,
    ],
  },
};

export const CHART_FONT_FAMILY =
  'PlusJakarta,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

export const DEFAULT_LINE_CHART = {
  type: 'line',
};

export const DEFAULT_BAR_CHART = {
  type: 'bar',
  backgroundColor: 'rgb(31, 147, 255)',
};

export const DEFAULT_CHART = {
  datasets: [DEFAULT_LINE_CHART],
  scales: {
    xAxes: [
      {
        ticks: {
          fontFamily: CHART_FONT_FAMILY,
        },
        gridLines: {
          drawOnChartArea: true,
        },
      },
    ],
    yAxes: [
      {
        id: 'y-left',
        type: 'linear',
        position: 'left',
        ticks: {
          fontFamily: CHART_FONT_FAMILY,
          beginAtZero: true,
          callback: value => {
            return Number.isInteger(value) ? value : null;
          },
        },
        gridLines: {
          drawOnChartArea: true,
        },
      },
    ],
  },
};

const TIME_CHART_CONFIG = {
  datasets: [DEFAULT_LINE_CHART],
  scales: {
    xAxes: [
      {
        ticks: {
          fontFamily: CHART_FONT_FAMILY,
        },
        gridLines: {
          drawOnChartArea: true,
        },
      },
    ],
    yAxes: [
      {
        id: 'y-left',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          fontFamily: CHART_FONT_FAMILY,
          callback: value => {
            let days = Math.floor(value / (3600 * 24));
            let hours = Math.floor((value % (3600 * 24)) / 3600);
            let minutes = Math.floor((value % 3600) / 60);
            let seconds = value % 60;

            if (value < 60) {
              return `${seconds}s`;
            }
            if (value < 3600) {
              return `${minutes}m ${seconds}s`;
            }
            if (value < 86400) {
              return `${hours}h ${minutes}m`;
            }
            return `${days}d ${hours}h`;
          },
        },
        gridLines: {
          drawOnChartArea: true,
        },
      },
    ],
  },
};

export const METRIC_CHART = {
  conversations_count: DEFAULT_CHART,
  conversations_total_count: DEFAULT_CHART,
  incoming_messages_count: DEFAULT_CHART,
  outgoing_messages_count: DEFAULT_CHART,
  avg_first_response_time: TIME_CHART_CONFIG,
  reply_time: TIME_CHART_CONFIG,
  avg_resolution_time: TIME_CHART_CONFIG,
  resolutions_count: DEFAULT_CHART,
};

export const OVERVIEW_METRICS = {
  open: 'OPEN',
  unattended: 'UNATTENDED',
  unassigned: 'UNASSIGNED',
  online: 'ONLINE',
  busy: 'BUSY',
  offline: 'OFFLINE',
};
