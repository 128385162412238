import { CONVERSATION_PRIORITY_ORDER } from 'shared/constants/messages';

export const findPendingMessageIndex = (chat, message) => {
  const { echo_id: tempMessageId } = message;
  return chat.messages.findIndex(
    m => m.id === message.id || m.id === tempMessageId
  );
};

export const filterByStatus = (
  chatStatus,
  filterStatus,
  conversation,
  currentUserDetails
) => {
  if (filterStatus === 'all') {
    return true;
  }

  if (chatStatus !== filterStatus) {
    return false;
  }

  if (filterStatus === 'snoozed') {
    const conversationAssigneeId = conversation.meta?.assignee?.id || null;
    const isUserASuperAdmin = (currentUserDetails?.type || '') === 'SuperAdmin';
    if (isUserASuperAdmin) {
      return true;
    }
    const isCurrentUserAssigned = (!conversationAssigneeId || (currentUserDetails?.id || 0) === conversationAssigneeId);
    return isCurrentUserAssigned;
  }
  return true;
}

export const filterByInbox = (shouldFilter, inboxId, chatInboxId) => {
  const isOnInbox = Number(inboxId) === chatInboxId;
  return inboxId ? isOnInbox && shouldFilter : shouldFilter;
};

export const filterByTeam = (shouldFilter, teamId, chatTeamId) => {
  const isOnTeam = Number(teamId) === chatTeamId;
  return teamId ? isOnTeam && shouldFilter : shouldFilter;
};

export const filterByLabel = (shouldFilter, labels, chatLabels) => {
  const isOnLabel = labels.every(label => chatLabels.includes(label));
  return labels.length ? isOnLabel && shouldFilter : shouldFilter;
};
export const filterByUnattended = (
  shouldFilter,
  conversationType,
  firstReplyOn,
  waitingSince
) => {
  return conversationType === 'unattended'
    ? (!firstReplyOn || !!waitingSince) && shouldFilter
    : shouldFilter;
};

export const getAppliedFiltersConversations = ({ conversations, filters }) => {
  const filteredConversations = conversations.filter(conversation => {
    const result = applyFiltersToConversation({ conversation, filters });
    return result;
  });

  return filteredConversations;
};

const getNestedValue = (obj, path) => {
  const value = path.split('.').reduce((value, key) => value && value[key], obj);
  return value;
};

const applyFiltersToConversation = ({ conversation, filters }) => {
  if ((conversation.meta.team || {}).id) {
    conversation.team_id = conversation.meta.team.id;
  }
  if ((conversation.meta.assignee || {}).id) {
    conversation.assignee_id = conversation.meta.assignee.id;
  }

  return filters.reduce((result, filter, index) => {
    const { attribute_key, filter_operator, values, query_operator } = filter;

    let conversationValue = getNestedValue(conversation, attribute_key);
    const filterValues = Array.isArray(values) ? values : [values];

    let condition = false;

    switch (filter_operator) {
      case 'equal_to':
        condition = filterValues.some(value => value.id ? (value.id === 'all' ? true : value.id === conversationValue) : value === conversationValue);
        break;
      case 'not_equal_to':
        condition = !filterValues.some(value => value.id ? (value.id === 'all' ? true : value.id === conversationValue) : value === conversationValue);
        break;
      case 'contains':
        condition = filterValues.some(value => conversationValue.includes(value));
        break;
      case 'does_not_contain':
        condition = !filterValues.some(value => conversationValue.includes(value));
        break;
      case 'is_present':
        condition = conversationValue !== null && conversationValue !== undefined;
        break;
      case 'is_not_present':
        condition = conversationValue === null || conversationValue === undefined;
        break;
      case 'is_greater_than':
        conversationValue = new Date(conversationValue * 1000);
        condition = conversationValue > new Date(filterValues[0]);
        break;
      case 'is_less_than':
        conversationValue = new Date(conversationValue * 1000);
        condition = conversationValue < new Date(filterValues[0]);
        break;
      case 'days_before':
        const dateLimit = new Date();
        dateLimit.setDate(dateLimit.getDate() - filterValues[0]);
        conversationValue = new Date(conversationValue * 1000);
        condition = conversationValue < dateLimit;
        break;
      default:
        condition = true;
    }

    if (index === 0) {
      return condition;
    } else if (query_operator === 'and') {
      return result && condition;
    } else if (query_operator === 'or') {
      return result || condition;
    }

    return result;
  }, true);
};

export const applyPageFilters = (conversation, filters) => {
  const { inboxId, status, labels = [], teamId, conversationType, currentUserDetails } = filters;
  const {
    status: chatStatus,
    inbox_id: chatInboxId,
    labels: chatLabels = [],
    meta = {},
    first_reply_created_at: firstReplyOn,
    waiting_since: waitingSince,
  } = conversation;
  const team = meta.team || {};
  const { id: chatTeamId } = team;

  let shouldFilter = filterByStatus(
    chatStatus,
    status,
    conversation,
    currentUserDetails
  );
  shouldFilter = filterByInbox(shouldFilter, inboxId, chatInboxId);
  shouldFilter = filterByTeam(shouldFilter, teamId, chatTeamId);
  shouldFilter = filterByLabel(shouldFilter, labels, chatLabels);
  shouldFilter = filterByUnattended(
    shouldFilter,
    conversationType,
    firstReplyOn,
    waitingSince
  );

  return shouldFilter;
};

const SORT_OPTIONS = {
  last_activity_at_asc: ['sortOnLastActivityAt', 'asc'],
  last_activity_at_desc: ['sortOnLastActivityAt', 'desc'],
  created_at_asc: ['sortOnCreatedAt', 'asc'],
  created_at_desc: ['sortOnCreatedAt', 'desc'],
  priority_asc: ['sortOnPriority', 'asc'],
  priority_desc: ['sortOnPriority', 'desc'],
  waiting_since_asc: ['sortOnWaitingSince', 'asc'],
  waiting_since_desc: ['sortOnWaitingSince', 'desc'],
  sla_first: ['sortOnSlaFirst', 'desc']
};
const sortAscending = (valueA, valueB) => valueA - valueB;
const sortDescending = (valueA, valueB) => valueB - valueA;

const getSortOrderFunction = sortOrder =>
  sortOrder === 'asc' ? sortAscending : sortDescending;

export const sortConfig = {
  sortOnLastActivityAt: (a, b, sortDirection) =>
    getSortOrderFunction(sortDirection)(a.last_activity_at, b.last_activity_at),

  sortOnCreatedAt: (a, b, sortDirection) =>
    getSortOrderFunction(sortDirection)(a.created_at, b.created_at),

  sortOnPriority: (a, b, sortDirection) => {
    const DEFAULT_FOR_NULL = sortDirection === 'asc' ? 5 : 0;

    const p1 = CONVERSATION_PRIORITY_ORDER[a.priority] || DEFAULT_FOR_NULL;
    const p2 = CONVERSATION_PRIORITY_ORDER[b.priority] || DEFAULT_FOR_NULL;

    return getSortOrderFunction(sortDirection)(p1, p2);
  },

  sortOnWaitingSince: (a, b, sortDirection) => {
    const sortFunc = getSortOrderFunction(sortDirection);
    if (!a.waiting_since || !b.waiting_since) {
      if (!a.waiting_since && !b.waiting_since) {
        return sortFunc(a.created_at, b.created_at);
      }
      return sortFunc(a.waiting_since ? 0 : 1, b.waiting_since ? 0 : 1);
    }

    return sortFunc(a.waiting_since, b.waiting_since);
  },

  sortOnSlaFirst: (a, b, sortDirection = 'desc') => {
    const sortFunc = getSortOrderFunction(sortDirection);
    if (!a.waiting_since || !b.waiting_since) {
      if (!a.waiting_since && !b.waiting_since) {
        return sortFunc(a.last_activity_at, b.last_activity_at);
      }
      return sortFunc(a.waiting_since ? 0 : 1, b.waiting_since ? 0 : 1);
    }

    return sortFunc(a.waiting_since, b.waiting_since);
  },
};

export const sortComparator = (a, b, sortKey) => {
  const [sortMethod, sortDirection] =
    SORT_OPTIONS[sortKey] || SORT_OPTIONS.sla_first;
  return sortConfig[sortMethod](a, b, sortDirection);
};
