<template>
  <woot-dropdown-menu>
    <woot-dropdown-header :title="$t('SIDEBAR.SET_AVAILABILITY_TITLE')" />
    <woot-dropdown-item
      v-for="status in availabilityStatuses"
      :key="status.value"
      class="flex items-baseline"
    >
      <woot-button
        size="small"
        :color-scheme="status.disabled ? '' : 'secondary'"
        :variant="status.disabled ? 'smooth' : 'clear'"
        class-names="status-change--dropdown-button"
        @click="changeAvailabilityStatus(status.value)"
      >
        <availability-status-badge :status="status.value" />
        {{ status.label }}
      </woot-button>
    </woot-dropdown-item>
    <woot-dropdown-item class="flex flex-col">
      <woot-button
        size="small"
        color-scheme="secondary"
        variant="clear"
        class-names="status-change--dropdown-button"
        @click="showPausesSelector = !showPausesSelector"
      >
        <availability-status-badge status="pause" />
        Pausa
      </woot-button>
      <form
        v-if="showPausesSelector"
        class="w-4/5 self-center content-center mt-1"
        @submit.prevent="onSubmit"
      >
        <div class="w-full">
          <label class="text-center text-sm w-full">
            Selecione a pausa
            <select v-model="pausesModel" class="mt-1 h-8 text-xs">
              <option
                v-for="pause in pauses"
                :key="pause.id"
                :value="pause"
                class="text-xs"
              >
                {{ pause.name }}
              </option>
            </select>
          </label>
        </div>
        <div class="flex text-center justify-center gap-2 py-2 px-0">
          <woot-button
            :style="{
              display: 'inline-block',
              padding: '0.25rem 0.5rem',
              width: 'auto',
              textAlign: 'center',
            }"
            size="tiny"
            :disabled="!pausesModel"
          >
            Confirmar
          </woot-button>
        </div>
      </form>
    </woot-dropdown-item>
    <!-- <woot-dropdown-divider />
    <woot-dropdown-item class="m-0 flex items-center justify-between p-2">
      <div class="flex items-center">
        <fluent-icon
          v-tooltip.right-start="$t('SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT')"
          icon="info"
          size="14"
          class="mt-px"
        />

        <span
          class="my-0 mx-1 text-xs font-medium text-slate-600 dark:text-slate-100"
        >
          {{ $t('SIDEBAR.SET_AUTO_OFFLINE.TEXT') }}
        </span>
      </div>

      <woot-switch
        size="small"
        class="mt-px mx-1 mb-0"
        :value="currentUserAutoOffline"
        @input="updateAutoOffline"
      />
    </woot-dropdown-item> -->
    <woot-dropdown-divider />
    <woot-modal
      :show.sync="showPauseModal"
      :on-close="hidePauseModal"
      :show-close-button="false"
      :full-width="true"
      :is-pause-modal="true"
    >
      <pause-modal :pause="pausesModel" @close="hidePauseModal" />
    </woot-modal>
  </woot-dropdown-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import WootDropdownHeader from 'shared/components/ui/dropdown/DropdownHeader.vue';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider.vue';
import AvailabilityStatusBadge from '../widgets/conversation/AvailabilityStatusBadge.vue';
import wootConstants from 'dashboard/constants/globals';
import PauseModal from './PauseModal.vue';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;
const OFFLINE_STATUS_INDEX = 2;

export default {
  components: {
    WootDropdownHeader,
    WootDropdownDivider,
    WootDropdownMenu,
    WootDropdownItem,
    AvailabilityStatusBadge,
    PauseModal,
  },

  mixins: [clickaway, alertMixin],

  data() {
    return {
      isStatusMenuOpened: false,
      isUpdating: false,
      pausesModel: null,
      showPausesSelector: false,
      showPauseModal: false,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentUserAvailability: 'getCurrentUserAvailability',
      currentAccountId: 'getCurrentAccountId',
      currentUserAutoOffline: 'getCurrentUserAutoOffline',
      pausesState: 'pauses/getPauses',
    }),
    availabilityDisplayLabel() {
      const availabilityIndex = AVAILABILITY_STATUS_KEYS.findIndex(
        key => key === this.currentUserAvailability
      );
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[
        availabilityIndex
      ];
    },
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')
        .map((statusLabel, index) => {
          if (index === OFFLINE_STATUS_INDEX) return null;

          return {
            label: statusLabel,
            value: AVAILABILITY_STATUS_KEYS[index],
            disabled:
              this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
          };
        })
        .filter(status => status);
    },
    pauses() {
      return this.$store.getters['pauses/getPauses'];
    },
  },
  mounted() {
    this.$store.dispatch('pauses/getPauses');
  },
  methods: {
    openStatusMenu() {
      this.isStatusMenuOpened = true;
    },
    closeStatusMenu() {
      this.isStatusMenuOpened = false;
    },
    updateAutoOffline(autoOffline) {
      this.$store.dispatch('updateAutoOffline', {
        accountId: this.currentAccountId,
        autoOffline,
      });
    },
    changeAvailabilityStatus(availability) {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      try {
        this.$store.dispatch('updateAvailability', {
          availability,
          account_id: this.currentAccountId,
        });
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.SET_AVAILABILITY_ERROR')
        );
      } finally {
        this.isUpdating = false;
      }
    },
    onSubmit() {
      this.showPauseModal = true;
    },
    hidePauseModal() {
      this.showPauseModal = false;
      this.delay(200).then(() => {
        this.$emit('close');
      });
    },
    delay(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms);
      });
    },
  },
};
</script>
