import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import SlaReports from '../../api/slaReport';
import { downloadCsvFile } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingMetrics: false,
  },
  metrics: {},
};

export const getters = {
  getSlaReport(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getMetrics(_state) {
    return _state.metrics;
  },
};

export const actions = {
  get: async function getslas({ commit }, params) {
    commit(types.SET_SLA_REPORT_UI_FLAG, { isFetching: true });
    try {
      const response = await SlaReports.get(params);
      commit(types.SET_SLA_REPORT, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SLA_REPORT_UI_FLAG, { isFetching: false });
    }
  },
  getMetrics: async function getMetrics({ commit }, params) {
    commit(types.SET_SLA_REPORT_UI_FLAG, { isFetchingMetrics: true });
    try {
      const response = await SlaReports.getMetrics(params);
      commit(types.SET_SLA_REPORT_METRICS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SLA_REPORT_UI_FLAG, { isFetchingMetrics: false });
    }
  },
  downloadSlaReport(_, params) {
    return SlaReports.download(params).then(response => {
      downloadCsvFile(params.fileName, response.data);
      AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
        reportType: 'sla',
      });
    });
  },
};

export const mutations = {
  [types.SET_SLA_REPORT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_SLA_REPORT]: MutationHelpers.set,
  [types.SET_SLA_REPORT_METRICS](_state, data) {
    _state.metrics = {
      ..._state.metrics,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
