var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"mentionsListContainer",staticClass:"bg-white dark:bg-slate-800 rounded-md overflow-auto absolute w-full z-20 pb-0 shadow-md left-0 bottom-full max-h-[50vh] border border-solid border-slate-100 dark:border-slate-700 mention--box",on:{"scroll":_vm.handleScroll}},[_c('ul',{staticClass:"vertical dropdown menu"},_vm._l((_vm.items),function(item,index){return _c('woot-dropdown-item',{key:item.key,staticClass:"!mb-0",attrs:{"id":`mention-item-${index}`},on:{"mouseover":function($event){return _vm.onHover(index)}}},[_c('button',{staticClass:"flex group flex-col gap-0.5 overflow-hidden cursor-pointer items-start py-2.5 px-2.5 justify-center w-full h-full text-left hover:bg-woot-50 dark:hover:bg-woot-800 border-b border-solid border-slate-100 dark:border-slate-700",class:{
          ' bg-woot-25 dark:bg-woot-800': index === _vm.selectedIndex,
        },on:{"click":function($event){return _vm.onListItemSelection(index)}}},[_c('p',{staticClass:"text-slate-900 dark:text-slate-100 group-hover:text-woot-500 dark:group-hover:text-woot-500 font-medium mb-0 text-sm overflow-hidden text-ellipsis whitespace-nowrap min-w-0 max-w-full",class:{
            'text-woot-500 dark:text-woot-500': index === _vm.selectedIndex,
          }},[_vm._v("\n          "+_vm._s(_vm.type === 'canned' ? _vm.variableKey(item) : item.description)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-slate-500 dark:text-slate-300 group-hover:text-woot-500 dark:group-hover:text-woot-500 mb-0 text-xs overflow-hidden text-ellipsis whitespace-nowrap min-w-0 max-w-full",class:{
            'text-woot-500 dark:text-woot-500': index === _vm.selectedIndex,
          }},[_vm._v("\n        "+_vm._s(_vm.type === 'canned' ? item.description : _vm.variableKey(item))+"\n      ")])])])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }